<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h4 class="title pull-left">
              ATTENDANCE LIST - <b>{{ title }}</b>
            </h4>

            <md-button
              class="md-warning md-sm pull-right"
              @click="refreshData"
            >
              Refresh Data
              <span class="md-label md-label-right">
                <md-icon class="material-icons">sync</md-icon>
              </span>
            </md-button>

            <md-button
              v-if="editable"
              class="md-primary md-sm pull-right"
              @click="onUpdate"
            >
              Save List
              <span class="md-label md-label-right ml-2">
                <md-icon class="material-icons">save</md-icon>
              </span>
            </md-button>

            <md-button
              v-if="editable"
              class="md-info md-sm pull-right"
              @click="onFillAll(true)"
            >
              Turn ON All
              <span class="md-label md-label-right ml-2">
                <md-icon class="material-icons">flash_on</md-icon>
              </span>
            </md-button>
            <md-button
              v-if="editable"
              class="md-danger md-sm pull-right"
              @click="onFillAll(false)"
            >
              Turn Off All
              <span class="md-label md-label-right ml-2">
                <md-icon class="material-icons">flash_off</md-icon>
              </span>
            </md-button>

            <md-button
              v-if="!editable"
              class="md-success md-sm pull-right"
              @click="editable = true"
            >
              Edit List
              <span class="md-label md-label-right">
                <md-icon class="material-icons">edit</md-icon>
              </span>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :editable="editable"
              extra-param-validation="inactive"
              :search="false"
              @changed="getAttendance"
              @itemChecked="itemChecked"
              @onCheckLogs="onCheckLogs"
            />

            <md-card-actions>
              <router-link
                :to="{name:'Attendances'}"
                tag="md-button"
                class="md-primary"
              >
                Return
              </router-link>
            </md-card-actions>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <AttendanceLogsModal
      v-if="showStudentLog"
      :student_number="showStudentLog.enrolment.programme.student.student_number"
      :date="showStudentLog.attendance_list.date"
      :att-enrolment="showStudentLog"
      @close="showStudentLog = null"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';
import AttendanceLogsModal from './AttendanceLogsModal.vue';

export default {
  components: {
    VueTable,
    AttendanceLogsModal,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'enrolment.programme.student.student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'enrolment.programme.student.first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'enrolment.programme.student.last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'first_class',
            mask: 'first class',
            checkeable: true,
            sortable: true,
          },
          {
            title: 'second_class',
            mask: 'second class',
            checkeable: true,
            sortable: true,
          },
          {
            title: 'status',
            mask: 'status',
          },
        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Attendance Logs',
            callback: 'onCheckLogs',
            icon: 'info',
          },
        ],
        values: {},
      },
      editable: false,
      showStudentLog: null,
    };
  },
  computed: {
    title() {
      let aux = null;
      if (this.vTable.values.data && this.vTable.values.data.length) {
        aux = this.vTable.values.data[0].attendance_list.classroom.classroom_code;
        aux += ` / ${moment(this.vTable.values.data[0].attendance_list.date).format(this.$store.getters['school_settings/settings'].date_format)}`;
      }
      return aux;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAttendance(params) {
      this.request((this.$API.ATTENDANCE_LIST + this.$route.params.id)
        .paginableUrl(params),
      'get',
      null,
      ({ data }) => {
        const aux = data;
        aux.data = aux.data.map((x) => {
          const y = x;
          y.status = x.inactive ? 'Inactive' : 'Enrolled';
          y.editable = !x.inactive;

          return y;
        });

        this.vTable.values = data;
      });
    },
    onUpdate() {
      this.request(this.$API.ATTENDANCE_LIST + this.$route.params.id, 'put',
        {
          attendances: this.vTable.values.data.map((x) => ({
            attendance_enrolment_id: x.attendance_enrolment_id,
            first_class: x.first_class,
            second_class: x.second_class,
          })),
        },
        () => {
          this.fireSuccess('Attendance List saved!');
          this.editable = false;
        });
    },
    itemChecked(val) {
      // Update the object
      this.vTable.values.data.forEach((x, i) => {
        if (x.attendance_enrolment_id === val.attendance_enrolment_id) {
          this.vTable.values.data[i] = val;
        }
      });
    },
    onFillAll(value) {
      this.vTable.values.data.forEach((x, i) => {
        if (x.editable) {
          this.vTable.values.data[i].first_class = value;
          this.vTable.values.data[i].second_class = value;
        }
      });
    },
    onCheckLogs(student) {
      this.showStudentLog = student;
    },
    refreshData() {
      this.fireConfirm(
        'Updating attendance data',
        `This action will set ABSENT for all the students in this attendance list, and then update Attendance from machines. 
        If someone updated any Attendance manually, it will be reverted. Are you sure?`,
      ).then(() => {
        this.request(`attendance/lists/${this.$route.params.id}/refresh`, 'post',
          null,
          () => {
            this.$refs.vtable.init();
          });
      });
    },
  },
};
</script>

<style scoped>
</style>
